*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-color-br:#a7553c;
    --main-color-gr:#e5e5e5;
    --main-color-wh:#ffffff;
    --main-color-bl:#424242;
}

header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 1px;
    justify-content: space-between;
    height: 80px;
    background-color: var(--main-color-br);
    color: var(--main-color-wh);
}

.navbar-links-container {
    /* gap: 30px; */
    gap: calc(8vw - 50px);
    font-family: 'Roboto-Regular', sans-serif;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
    /* font-size: 12px; */
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
    letter-spacing: 3px;
    text-transform: uppercase;
}



nav a{
    color: var(--main-color-wh);
    text-decoration: none;
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

nav a:hover {
    color: var(--main-color-bl);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.nav-logo {
    height: 40px;
}

header .nav-btn {
    padding: 5px;
    width: 2rem;
    cursor: pointer;
    background: transparent;
    color:var(--main-color-wh);
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    display: none;

}

header .nav-btn svg {
    width: 24px;
    height: 24px;
}

@media only screen and (max-width:1024px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    header nav{
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--main-color-br);
        transition: 1s;
        transform: translateX(150vh);
        
    }

    header .responsive_nav {
        transform: none;
        box-shadow: 0px 0px 1px 99999px rgba(0,0,0,0.65);
        transition: .5s;
        gap: 5%;
        font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1024 - 300)));
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    
}

