
.box {
    display: block;
  background-color: transparent;
  background-image: repeating-linear-gradient(240deg, #ffffff, #ffffff 0px, transparent 15px, transparent 15px, #ffffff 45px), repeating-linear-gradient(330deg, #ffffff, #ffffff 0px, transparent 15px, transparent 15px, #ffffff 45px), repeating-linear-gradient(60deg, #ffffff, #ffffff 0px, transparent 15px, transparent 15px, #ffffff 45px), repeating-linear-gradient(150deg, #ffffff, #ffffff 0px, transparent 15px, transparent 15px, #ffffff 45px);
background-size: 2px calc(100% + 90px), calc(100% + 90px) 2px, 2px calc(100% + 90px) , calc(100% + 90px) 2px;
background-position: 0 0, 0 0, 100% 0, 0 100%;
background-repeat: no-repeat;
animation: borderAnimation 1s infinite linear reverse;
font-family: 'Open Sans Condensed', sans-serif;
outline: none;
color: #ffffff;
}

@keyframes borderAnimation {
from { background-position: 0 0, -90px 0, 100% -90px, 0 100%; }
to { background-position: 0 -90px, 0 0, 100% 0, -90px 100%; }
}