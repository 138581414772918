/* ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-to-top-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--main-color-br);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.5);
  }
  
  .scroll-to-top-button:hover {
    background-color: var(--main-color-bl);
  }
  
  .scroll-to-top-button svg {
    fill: none;
    stroke: white;
    stroke-width: 2;
  }
  